// import images
import recycle from '../images/1-reciclar.png';
import use_natural_light from '../images/2-luces.png';
import use_less_car from '../images/3-bicicleta.png';
import use_less_bags from '../images/4-bolsa.png';
import unplug_home_appliances from '../images/5-desenchufar.png';
import close_the_tap from '../images/6-grifos.png';
import eat_national_fruits from '../images/7-frutas.png';
import repair_all from '../images/8-reparar.png';
import dont_disturb_animals_home from '../images/9-nidos.png';
import dont_use_disposable_packaging from '../images/10-botellas.png';

const commitments = [
  {
    id: 'recycle',
    imageSrc: recycle,
    title: 'Reciclar',
    description: 'Separaré los residuos orgánicos del metal, cartón, papel y vidrio.',
  },
  {
    id: 'use_natural_light',
    imageSrc: use_natural_light,
    title: 'Apagar las luces y aprovechar la luz natural',
    description: 'Para reducir el consumo de luz eléctrica, abriré las ventanas para que entre la luz del Sol a mi casa.',
  },
  {
    id: 'use_less_car',
    imageSrc: use_less_car,
    title: 'Utilizar mi carro lo menos posible esta semana',
    description: 'Caminaré más y utilizaré la bicicleta.',
  },
  {
    id: 'use_less_bags',
    imageSrc: use_less_bags,
    title: 'Llevar bolsas al supermercado',
    description: 'Llevaré mis propias bolsas reutilizables. Empacaré varios productos en una sola bolsa.',
  },
  {
    id: 'unplug_home_appliances',
    imageSrc: unplug_home_appliances,
    title: 'Desenchufar los electrodomésticos',
    description: 'Los aparatos que están apagados pero enchufados consumen energía, por eso los desconectaré si no están en uso. ',
  },
  {
    id: 'close_the_tap',
    imageSrc: close_the_tap,
    title: 'Cerrar los grifos correctamente',
    description: 'Cuando no esté utilizando el agua, cerraré el grifo y controlaré que no existan fugas.',
  },
  {
    id: 'eat_national_fruits',
    imageSrc: eat_national_fruits,
    title: 'Consumir frutas y verduras de mercados campesinos',
    description: 'Adquiriré alimentos que cuiden el Medio Ambiente y que no utilicen fertilizantes o productos contaminantes.',
  },
  {
    id: 'repair_all',
    imageSrc: repair_all,
    title: 'Reutilizar y reparar todo lo que pueda',
    description: 'Antes de tirar a la basura ropa, libros o juguetes, buscaré la manera de darles una segunda oportunidad. ',
  },
  {
    id: 'dont_disturb_animals_home',
    imageSrc: dont_disturb_animals_home,
    title: 'No molestar el lugar donde viven los animales',
    description: 'No tocaré los nidos de las aves, no capturaré animales y respetaré su hábitat.',
  },
  {
    id: 'dont_use_disposable_packaging',
    imageSrc: dont_use_disposable_packaging,
    title: 'Evitar el uso de envases desechables',
    description: 'Preferiblemente, utilizaré vasos de vidrio, recipientes de loza o termos.',
  },
];


export default commitments;