import React from 'react';
import './App.css';
// How to use materialize-css with react
// https://stackoverflow.com/questions/35499842/how-to-use-materialize-css-with-react

import 'materialize-css/dist/css/materialize.min.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { TransitionGroup, Transition } from "react-transition-group";
import { play, exit } from './timelines';
import Form from './views/Form';
import Results from './views/Results';
import Navbar from './components/Navbar';

// Implement a counter with firebase
// https://makaroni4.com/2018/12/18/rad-workout-app/

// How to use firebase and react
// https://carlosazaustre.es/conectando-firebase-a-react
// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial#firebase-realtime-database-in-react

// Animations on screen enter/exit https://css-tricks.com/animating-between-views-in-react/
// example code: https://codesandbox.io/s/github/jeremenichelli/react-view-transition-example/tree/master/?from-embed=&file=/src/App.js

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Route render={({ location }) => {
        const { pathname, key } = location;

        return (
          <TransitionGroup component={null}>
            <Transition
              key={key}
              appear={true}
              onEnter={(node, appears) => play(pathname, node, appears)}
              onExit={(node, appears) => exit(node, appears)}
              timeout={{ enter: 750, exit: 150 }}
            >
              <Switch location={location}>
                <Route exact path="/" component={Form} />
                <Route path="/results" component={Results} />
                {/* <Route path="/about" component={About} /> */}
              </Switch>
            </Transition>
          </TransitionGroup>
        )
      }} />
      <div>
        <div style={{
          backgroundColor: '#7AD75B'
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
            <path id="curve" fill="#FAFCFF" fillOpacity="1" d="M0,96L80,112C160,128,320,160,480,154.7C640,149,800,107,960,96C1120,85,1280,107,1360,117.3L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
        <div className='footer-background center-align'>
          Diseño y desarrollo por: Teleantioquia Digital
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
