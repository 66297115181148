import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// How to use materialize-css with react
// https://stackoverflow.com/questions/35499842/how-to-use-materialize-css-with-react

import 'materialize-css/dist/css/materialize.min.css';
import './Navbar.css';

// Animations on screen enter/exit https://css-tricks.com/animating-between-views-in-react/
// example code: https://codesandbox.io/s/github/jeremenichelli/react-view-transition-example/tree/master/?from-embed=&file=/src/App.js

function Results() {
    return (
        <div className="navbar-container row">
            <div className="col s12 right-align">
                <a target="_blank" className="link social" href="https://www.facebook.com/CanalTeleantioquia/" rel="noopener noreferrer"><i
                    className="fab fa-facebook-f color-green3 icon-size mx-2"></i></a>
                <a target="_blank" className="link social" href="https://www.youtube.com/user/CanalTeleantioquia" rel="noopener noreferrer"><i
                    className="fab fa-youtube color-green3 icon-size mx-2"></i></a>
                <a target="_blank" className="link social" href="https://www.instagram.com/teleantioquia" rel="noopener noreferrer"><i
                    className="fab fa-instagram color-green3 icon-size mx-2"></i></a>
                <a target="_blank" className="link social" href="https://twitter.com/Teleantioquia" rel="noopener noreferrer"><i
                    className="fab fa-twitter color-green3 icon-size mx-2"></i></a>

                <a target="_blank" className="btn btn-navbar-custom waves-effect waves-light" name="action" href="https://www.teleantioquia.co/" rel="noopener noreferrer">Ir a Teleantioquia</a>
            </div>
        </div>
    );
}

export default Results;
