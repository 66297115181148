import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// How to use materialize-css with react
// https://stackoverflow.com/questions/35499842/how-to-use-materialize-css-with-react

import 'materialize-css/dist/css/materialize.min.css';
import './Card.css';

function Card(props) {
  const {
    title,
    imageSrc,
    description,
    isActive,
    onClick,
  } = props;

  return (
    <div className={`card-selectable ${!isActive || 'commitment-card-active'}`} onClick={onClick}>
      <img className='card-selectable-icon' src={imageSrc} alt='' />
      <p className="card-selectable-title">{title}</p>
      <p>{description}</p>
    </div>
  );
}

Card.defaultProps = {
  id: 'not_defined',
  title: 'Card Title',
  imageSrc: 'https://via.placeholder.com/98',
  description: 'I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.',
  isActive: false,
}

export default Card;
