import React, { useEffect, useState } from 'react';
import logo from '../images/yo-me-comprometo.png';
// How to use materialize-css with react
// https://stackoverflow.com/questions/35499842/how-to-use-materialize-css-with-react
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import './Forms.css';
import './FormsBorders.css';
import Card from '../components/Card';
import uuid from 'react-uuid'
import { useFormFields } from '../libs/hookLibs';
import commitments from '../data/commitments';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { TimelineMax } from 'gsap';
import leftBg from '../images/left-bg.png';
import rightBg from '../images/right-bg.png';
import treeImage from '../images/arboles.png';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBA5zswPu0bGk0Sbxrl15Lbnm0VLOzMPh8",
  authDomain: "the-tree-project-2aa6e.firebaseapp.com",
  databaseURL: "https://the-tree-project-2aa6e.firebaseio.com",
  projectId: "the-tree-project-2aa6e",
  storageBucket: "the-tree-project-2aa6e.appspot.com",
  messagingSenderId: "62112724454",
  appId: "1:62112724454:web:2c72777e5a1a8b134a5227",
  measurementId: "G-VWY1T6NNV0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function writeSessionData(userId, fields) {
  firebase.database().ref('sessions/' + userId).set({
    ...fields
  });
}

const sessionId = uuid();

// Animations on screen enter/exit https://css-tricks.com/animating-between-views-in-react/
// example code: https://codesandbox.io/s/github/jeremenichelli/react-view-transition-example/tree/master/?from-embed=&file=/src/App.js

// Reference to the firebase counter app
const counterReference = firebase.database().ref('tree_counter');
const bottomPositions = [15, 0, 25, 18, 0, 12, 8, 6, 8, 0];
function Form() {
  const [fields, handleFieldChange] = useFormFields({
    nombre: "",
    edad: "",
    municipio: "",
    correo: "",
    commitment_id: "",
  });
  const [activeCommitmentId, setActiveCommitmentId] = useState('');
  const [treeCounter, setTreeCounter] = useState(0);
  const [displayedTreeImages, setDisplayedTreeImages] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [treeContainerWidth, setTreeContainerWidth] = useState(0)

  // Get tree container widht
  useEffect(() => {
    setTreeContainerWidth(document.getElementById('modal-tree-container').offsetWidth);
  }, []);

  const generateTree = (count) => {
    return Array.from(Array(count)).map((value, index) => {
      if (index === 0) {
        return <img key={index} alt='' src={treeImage} className='tree-image tree-enter-animation' />
      } else {
        let positionX;
        if ((index & 1) === 1) {
          positionX = {
            left: (index - 1) * 15,
            bottom: bottomPositions[index % bottomPositions.length - 1],
          }
        } else {
          positionX = {
            right: (index - 2) * 15,
            bottom: bottomPositions[index % bottomPositions.length - 1],
          }
        }
        return <img key={index} alt='' src={treeImage} style={{
          position: 'absolute',
          ...positionX
        }} className='tree-image tree-enter-animation' />
      }
    });
  }

  useEffect(() => {
    counterReference.on('value', (snapshot) => {
      const val = snapshot.val();
      setTreeCounter(val);
      setDisplayedTreeImages((prevState) => {
        return prevState + 1;
      });
    });
  }, []);

  const onSubmit = () => {
    const elem = document.getElementById('modal1');
    var instance = M.Modal.getInstance(elem);
    instance.open();

    counterReference.transaction(val => val + 1, (err) => {
      if (err) {
        console.log(err);
      }
    });

    try {
      writeSessionData(sessionId, fields);
    }
    catch {
      console.log('Session couldnt be writen to database however the tree was counted');
    }
  }

  const onCloseResult = () => {
    const elem = document.getElementById('modal1');
    var instance = M.Modal.getInstance(elem);
    instance.close();
  }

  function validateForm() {
    // don't remember from where i copied this code, but this works.
    // eslint-disable-next-line no-useless-escape
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      fields.nombre.length > 0 &&
      fields.edad.length > 0 && !isNaN(fields.edad) &&
      fields.municipio.length > 0 &&
      fields.correo.length > 0 && re.test(fields.correo) &&
      fields.commitment_id.length > 0 && fields.commitment_id !== 'not_defined'
    );
  }

  const generateCommitments = () => {
    return commitments.map((item, index) => {
      let isActive = false;
      if (item.id === activeCommitmentId) {
        isActive = true;
      }
      return (
        <div key={index} className='Rtable-cell'>
          <Card groupName='commitment_id' onClick={() => {
            setActiveCommitmentId(item.id);
            handleFieldChange({
              target: {
                id: 'commitment_id',
                value: item.id,
              }
            });
          }} imageSrc={item.imageSrc} isActive={isActive} title={item.title} description={item.description} />
        </div>
      );
    })
  }

  // Initialize Materialize elements
  useEffect(() => {
    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  }, []);

  // Animations
  useEffect(() => {
    const nodes = document.querySelectorAll('.bg-animated');
    var curve = document.getElementById('curve');

    const timeline = new TimelineMax({ paused: true, repeat: -1, });
    timeline
      .to(nodes[0], 5, { translateX: -30, rotation: 10, ease: 'Power2.easeInOut' }, 'step1')
      .to(nodes[0], 5, { translateX: 0, rotation: 0, ease: 'Power2.easeInOut' }, 'step2');

    timeline
      .to(nodes[1], 5, { translateX: 20, ease: 'Power2.easeInOut' }, 'step1')
      .to(nodes[1], 5, { translateX: 0, ease: 'Power2.easeInOut' }, 'step2');

    timeline.to(curve, 5, { scaleY: 1.2, opacity: 1 }, 'step1');
    timeline.to(curve, 5, { scaleY: 1, opacity: 1 }, 'step2');
    // timeline.to(curve, 5, { scaleY: 1, opacity: 1 });
    if (!window.matchMedia('(max-width: 998px)').matches) {
      window
        .loadPromise
        .then(() => requestAnimationFrame(() => timeline.play()))
    }

    window.addEventListener('resize', function () {
      if (!window.matchMedia('(max-width: 998px)').matches) {
        window
          .loadPromise
          .then(() => requestAnimationFrame(() => timeline.play()))
      } else {
        timeline.pause();
        timeline.time(0);
      }
    });

    var elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      onOpenEnd: function(modal, trigger) { // Callback for Modal open. Modal and trigger parameters available.
        // console.log(modal, trigger);
        setDisplayedTreeImages(1);
        timeline.pause();
        timeline.time(0);
      },
      onCloseEnd: function() { // Callback for Modal close
        window
          .loadPromise
          .then(() => requestAnimationFrame(() => timeline.play()));
      },
    });
  }, []);

  return (
    <div className="container">
      <img src={leftBg} alt='' className='bg-animated left-background' />
      <img src={rightBg} alt='' className='bg-animated right-background' />
      <div id="stagger-animation">
        {/* Basic data */}
        <div className="row first-row">
          <div className="col s12 offset-m3 m6">
            <div className="col s12">
              <div className='row'>
                <div className="col s12 center-align">
                  <img alt='' className='image-fluid-logo' src={logo} />
                </div>
              </div>

              <div className='row'>
                <div className='col s12 center-align'>
                  {/* <p>Hoy celebramos el día del Medio Ambiente y en Teleantioquia nos emociona impulsar pequeñas grandes acciones para <span className='bold'>proteger el planeta.</span></p> */}
                  <p>El 5 de junio celebramos el día del Medio Ambiente y en Teleantioquia nos emociona impulsar pequeñas grandes acciones para <span className='bold'>proteger el planeta.</span></p>
                  <p>Diligencia el formulario y elige cómo te vas a comprometer a cuidar la naturaleza. La Secretaría del Medio Ambiente del departamento y Teleantioquia sembraremos un árbol por cada compromiso que recibamos.</p>
                  <p>Este año cumplimos 35 y este es uno de los regalos.</p>
                  {/* <p>En el día del Medio Ambiente nos comprometemos a cambiar nuestros hábitos para cuidar y respetar la naturaleza. Comprométete con el medio ambiente para que <span className='bold'>unidos sembremos 35 mil árboles.</span></p> */}
                </div>
              </div>
              <div className='form-data-container px-2 py-2'>
                <div className="row">
                  <div className="input-field col s12 m8">
                    <input id="nombre" type="text" className="validate" value={fields.nombre} onChange={handleFieldChange} />
                    <label htmlFor="nombre">Nombre</label>
                  </div>
                  <div className="input-field col s12 m4">
                    <input id="edad" type="number" className="validate" min="1" value={fields.edad} onChange={handleFieldChange} />
                    <label htmlFor="edad">Edad</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <input id="municipio" type="text" className="validate" value={fields.municipio} onChange={handleFieldChange} />
                    <label htmlFor="municipio">Municipio</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <input id="correo" type="email" className="validate" value={fields.correo} onChange={handleFieldChange} />
                    <label htmlFor="correo">Correo</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12 center-align'>
                    <p>Consulta nuestra política de tratamiento de información personal <a target="_blank" rel="noopener noreferrer" href="https://www.teleantioquia.co/wp-content/uploads/2015/04/tratamiento-de-infomacion-personal-de-terceros.pdf">aquí.</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Commitments */}
        <div className="row">
          <div className="col s12 center-align">
            <h5 className='bold'>Yo me comprometo a</h5>
            <div className="row">
              <div className="col s12 offset-m2 m8">
                <p className="subtitle">Selecciona uno de los siguientes compromisos</p>
              </div>
            </div>
            <div className='row'>
              {/* Inspiration from: https://css-tricks.com/accessible-simple-responsive-tables/ */}
              <div className='Rtable Rtable--5cols Rtable--collapse'>
                {generateCommitments()}
              </div>
            </div>

            {/* Submit button */}
            <div className="row">
              <div className="col s12 center-align">
                <button disabled={!validateForm()} onClick={onSubmit} className="btn btn-custom waves-effect waves-light" name="action">¡Enviar compromiso!</button>
                {/* <button onClick={onSubmit} className="btn btn-custom waves-effect waves-light" name="action">HACER COMPROMISO</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Structure */}
      <div id="modal1" className="modal">
        <div className="modal-content center-align">
          <div id="modal-tree-container" className='tree-containers'>
            {generateTree(displayedTreeImages)}
          </div>
          <h5>¡Gracias por tu compromiso!</h5>
          <div className='tree-modal-subtitle'>Ya son <span id="result-zone"><span>{treeCounter}</span> árboles</span> que sembraremos este 11 de agosto en nuestro cumpleaños.</div>
          <p>Todos los seres del planeta estamos conectados.</p>
          <button onClick={onCloseResult} className="btn btn-custom waves-effect waves-light" name="action">HACER OTRO COMPROMISO</button>
        </div>
      </div>
    </div>
  );
}

export default Form;
