import React, { useEffect } from 'react';
// import logo from './logo.svg';
// import './App.css';
// How to use materialize-css with react
// https://stackoverflow.com/questions/35499842/how-to-use-materialize-css-with-react
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';

// Animations on screen enter/exit https://css-tricks.com/animating-between-views-in-react/
// example code: https://codesandbox.io/s/github/jeremenichelli/react-view-transition-example/tree/master/?from-embed=&file=/src/App.js

function Results() {
  // Initialize forms
  useEffect(() => {
    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  }, []);

  return (
    <div className="row">
      <div className="col s12">
        <h1>Results</h1>
      </div>
    </div>
  );
}

export default Results;
